import {API_URL, FILTERS_PAGE, CHART_CONFIG, VITEK_MS, VITEK_PRIME} from "../../const.js";
const API_KEY = process.env.VUE_APP_AWS_API_KEY;

import {default as http} from "axios";
import moment from "moment/moment";
const AUTH_TOKEN = localStorage.getItem("authToken");
const newAxiosSetup = (state) => {
	let token = AUTH_TOKEN;
	let storeToken = state.userToken;
	if ((token === null || token !== storeToken) && storeToken !== null && storeToken !== "") {
		token = storeToken;
	}
	return http.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "x-api-key": API_KEY
    }
	});
}

const axiosRequest = async (config, state) => {
	let newAxios = newAxiosSetup(state);
	let result = await newAxios(config).then(function (response) {
		if (response.status !== 200) {
			console.log("error", response);
			return response;
		}
		return  response.data;
	}).catch((error) => {
		console.log("error", error);
		return {error, status: 500};
	});
	return result;
};

const constructChartData = (chartData, ft_date, instrument_type) => {
	let ft_date_categories = ft_date.map((item) => item.category);
	let dates = [];
	if (instrument_type !== VITEK_MS && instrument_type !== VITEK_PRIME) {
		instrument_type = VITEK_MS;
	}

	let typeKey = instrument_type === VITEK_MS ? "Median of number of good peaks" : "Median of MaxI";
	let parsedData = {
		"Median of number of peaks": [],
		"Fine Tuning date" : ft_date.map((item) =>
		{
			return {
				x : item.date,
				y :  0,
				category : item.category
			}
		}),
		"Warning level": Object.values(CHART_CONFIG?.DEFAULT_WARNING_LEVEL?.[instrument_type])
	};
	if (instrument_type === VITEK_MS){
		parsedData["Median of number of good peaks prediction"] = [];
		parsedData["Median of number of peaks prediction"] = [];
	}
	parsedData[typeKey] = []
	chartData.forEach((item) => {
		parsedData["Median of number of peaks"].push({
			x: item.date,
			y: item["Median of number of peaks"],
			strain : item.strain
		});
		parsedData[typeKey].push({
			x: item.date,
			y: item[typeKey],
			strain : item.strain
		});
		if (instrument_type === VITEK_MS){
			parsedData["Median of number of good peaks prediction"].push({
				x: item.date,
				y: item["Median of number of good peaks prediction"],
				strain : item.strain
			});
			parsedData["Median of number of peaks prediction"].push({
				x: item.date,
				y: item["Median of number of peaks prediction"],
				strain : item.strain
			});
		}
		dates.push(item.date);
	});
	return { parsedData, dates, ft_date_categories };
}

const getFTDate = async (state, payload) => {
	let config = {
		method: "get",
		url: `${API_URL}/v1/ft-date`,
		headers: {
			"Content-Type": "application/json"
		},
		params: {
			"instrument_id": payload.instrument_ID ? payload.instrument_ID : state.selectedItem.instrument_id,
			"ft_date_categories": payload.ft_date_categories,
			"last": payload.last ? payload.last : state.selectedItem.last,
			"min_date": payload.min_date ? payload.min_date : state.selectedItem.min_date,
			"max_date": payload.max_date ? payload.max_date : state.selectedItem.max_date
		}
	}
	if (config.params.min_date === undefined) {
		config.params.min_date = moment().subtract(1, "years").format("YYYY-MM-DD");
	}
	if (config.params.max_date === undefined) {
		config.params.max_date = moment().format("YYYY-MM-DD");
	}
	let result = await axiosRequest(config, state);
	return result;
}

const getChartData = async (state, payload) => {
	let config = {
		method: "get",
		url: `${API_URL}/v1/instrument-chart`,
		headers: {
			"Content-Type": "application/json"
		},
		params: {
			"instrument_id": payload.instrument_ID ? payload.instrument_ID : state.selectedItem.instrument_id,
			"min_date": payload.min_date ? payload.min_date : state.selectedItem.min_date,
			"max_date": payload.max_date ? payload.max_date : state.selectedItem.max_date
		}
	}
	let result = await axiosRequest(config, state);
	return result;
}

const getLastFTDateFromID = async (state, payload) => {
	let config = {
		method: "get",
		url: `${API_URL}/v1/ft-date`,
		headers: {
			"Content-Type": "application/json"
		},
		params: {
			"instrument_id": payload.id ? payload.id : state.selectedItem.instrument_id,
			"last": true
		}
	}
	let result = await axiosRequest(config, state);
	return result;
}

const getLastInstrumentComment = async (state, payload) => {
	let config = {
		method: "get",
		url: `${API_URL}/v1/comment`,
		headers: {
			"Content-Type": "application/json"
		},
		params: {
			"instrument_id": payload.id ? payload.id : state.selectedItem.instrument_id
		}
	}
	let result = await axiosRequest(config, state);
	return result;
};

const putFTDate = async (state, payload) => {
	if (payload.date > (new Date()).toISOString().slice(0, 10).replace(/-/g, "/"))
		return 0;
	let config = {
		method: "post",
		url: `${API_URL}/v1/ft-date`,
		headers: {
			"Content-Type": "application/json"
		},
		params : {
			"instrument_id": state.selectedItem.instrument_id,
			"date": payload.date
		}
	}
	let result = await axiosRequest(config, state);
	return result;
}


const putInstrumentComment = async (payload, state) => {
	let config = {
		method: "post",
		url: `${API_URL}/v1/comment`,
		headers: {
			"Content-Type": "application/json"
		},
		params : {
			"instrument_id": payload.instrument_ID,
			"comment": payload.comment
		}
	}
	let result = await axiosRequest(config, state);
	return result;
}

const getInstrumentList = async (state, filters) => {
	let config = {
		method: "get",
		url: `${API_URL}/v1/instrument-list`,
		headers: {
			"Content-Type": "application/json"
		}
	};
	if (state.customerEquipmentSN) {
		config.params = {
			"equipment_sn": state.customerEquipmentSN.join(","),
		}
	} else if (Object.entries(filters).length !== 0) {
		config.params = filters;
	}
	let result = await axiosRequest(config, state);
	return result;
};

const getInstrumentDetail = async (state) => {
	let config = {
		method: "get",
		url: `${API_URL}/v1/instrument`,
		headers: {
			"Content-Type": "application/json"
		},
		params: {
			"instrument_ID": state.selectedItem.instrument_id
		}
	};
	let result =  await axiosRequest(config, state);
	config.url = `${API_URL}/v1/instrument-list`
	result["instrument"] = (await axiosRequest(config, state))[0];
	return result;
};

const getFiltersPage = async (state, appliedFilters) => {
	if (state.customerEquipmentSN) {
		appliedFilters.instrument_ID = state.customerEquipmentSN.join(",");
	}
	let param = JSON.stringify(appliedFilters);
	let config = {
		method: "get",
		url: `${API_URL}/v1/filters`,
		headers: {
			"Content-Type": "application/json"
		},
		params: {
			"appliedFilters" : param,
			"filters" : FILTERS_PAGE.join(",")
		}
	};
	let result = await axiosRequest(config, state);
	return result;
};

const getInstrumentIdFromToken = async (token, state) => {
	let config = {
		method: "post",
		url: `${API_URL}/v1/check-token`,
		headers: {
			"Content-Type": "application/json"
		},
		params: {
			"token": token
		}
	};
	let result = await axiosRequest(config, state);
	return result;
}

const getLastUpdateDate = async (state) => {
	let config = {
		method: "get",
		url: `${API_URL}/v1/last-update`,
		headers: {
			"Content-Type": "application/json"
		}
	};
	let result = await axiosRequest(config, state);
	return result;
}

export default {getLastFTDateFromID, getLastUpdateDate, getInstrumentIdFromToken, constructChartData, getFTDate, putFTDate, getChartData, putInstrumentComment, getInstrumentList, getInstrumentDetail, getFiltersPage, getLastInstrumentComment };