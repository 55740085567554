<template>
    <div class="row justify-center">
        <q-dialog v-model="isFeedbackDialogOpen" full-width no-backdrop-dismiss class="feedback">
            <q-card style="max-width: 1200px;">
	            <div style="width: 100%; position: absolute; z-index: 1">
		            <q-card-actions align="right">
			            <q-btn color="bmx-blue" style="position:fixed; margin-top: 50px; background-color: ghostwhite;" outlined text @click="closeFeedback" flat>Close</q-btn>
		            </q-card-actions>
	            </div>
                <q-card-section>
                    <div class="row">
                        <p class="text-weight-bold text-uppercase text-bmx-blue col-9">Asset Details:</p>
                    </div>
                    <div class="row">
                        <div class="row col-2">
                            <p class="text-body2 text-grey-7 text-uppercase q-pr-xs">{{detailedInstrument.instrument_type}}:</p>
                            <p class="text-body2 text-grey-8 text-uppercase text-bold">
                                {{ detailedInstrument.instrument_id ? detailedInstrument.instrument_id : "N/A" }}
                            </p>
                        </div>
                        <div class="row col-5">
                            <p class="text-body2 text-grey-7 q-pr-xs">Customer Name:</p>
                            <p class="text-body2 text-grey-8 text-uppercase text-bold">
                                {{ detailedInstrument.customer_name ? detailedInstrument.customer_name : "N/A" }}
                            </p>
                        </div>
                        <div class="row col-2">
                            <p class="text-body2 text-grey-7 q-pr-xs">Region:</p>
                            <p class="text-body2 text-grey-8 text-uppercase text-bold">
                                {{ detailedInstrument.region ? detailedInstrument.region : "N/A" }}
                            </p>
                        </div>
		                    <div class="row col-2">
			                    <p class="text-body2 text-grey-8 q-pr-xs">SAP Status:</p>
			                    <p v-if="detailedInstrument.sap_status?.[0] === '1'" class="text-body2 text-red-8 text-uppercase text-bold">
				                    ERROR
			                    </p>
			                    <p v-else class="text-body2 text-green-8 text-uppercase text-bold">
				                    OK
			                    </p>
		                    </div>
                    </div>
	                <q-separator inset class="q-ma-sm" />
	                <div class="row q-mt-md">
		                <p class="text-weight-bold text-bmx-blue text-uppercase">Fine Tuning Informations:</p>
	                </div>
	                <div class="row">
		                <div class="row col-4">
				                <p class="text-body2 text-grey-7 q-my-auto q-pr-md">Fine Tuning Status:</p>
				                <Status :status="detailedInstrument?.instrument_status ?? 'null'" />
		                </div>
		                <div class="row col-4">
			                <p class="text-body2 text-grey-7 q-my-auto q-pr-md">Fine Tuning Recommended date:</p>
			                <p class="text-body2 text-grey-8 text-uppercase text-bold q-my-md">
				                {{ detailedInstrument.recommended_ft_date ? detailedInstrument.recommended_ft_date?.split("T")[0] : "N/A" }}
			                </p>
		                </div>
	                </div>
	                <div class="row" style="align-items: center">
		                <div class="row col-4">
			                <p class="text-body2 text-grey-7 q-my-md q-pr-md">Last Fine Tuning date:</p>
			                <p class="text-body2 text-grey-8 text-uppercase text-bold q-my-md">
				                {{ lastFtDate }}
			                </p>
		                </div>
		                <div class="row col-5">
			                <p class="text-body2 text-grey-7 q-my-md q-pr-md">Remaining Days:</p>
			                <p class="text-body2 text-grey-8 text-uppercase text-bold q-my-md">
				                <RemainingDays v-if="detailedInstrument?.recommended_ft_date" :date="detailedInstrument?.recommended_ft_date" />
				                <p v-else>N/A</p>
			                </p>
		                </div>
		                <div class="row col-3">
			                <q-btn label="Add Last Fine Tuning Date" @click="() => store.commit('setIsFineTuningDateDialogOpen', true)"
			                       class="full-width" style="height: 31px"  color="bmx-blue" icon-right="mdi-comment-text"></q-btn>
		                </div>
	                </div>
	                <div class="row">
		                <div class="row col-4">
			                <p class="text-body2 text-grey-7 q-my-md q-pr-md">Bad Spotting Alert:</p>
			                <Status   v-if="detailedInstrument?.bad_spotting_level" :status="detailedInstrument?.bad_spotting_level" type="bad_spotting"  />
			                <p v-else>N/A</p>
		                </div>
	                </div>
	                <q-separator inset class="q-ma-sm" />
	                <div class="row q-mt-md">
		                <p class="text-weight-bold text-bmx-blue text-uppercase">Fine Tuning Comment:</p>
	                </div>
	                <div class="row justify-around" style="align-items: center">
		                <div class="row col-9 no-padding no-margin">
			                <p class="text-body2 text-grey-7 q-my-md q-mr-md">Last Comment:</p>
			                <q-input
					                class="row"
					                type="textarea"
					                style="width: 80%"
					                v-model="lastComment.comment"
					                placeholder="No comment"
					                color="blue" disable autogrow outlined
					                rows="2" row-height="15"
			                >
			                </q-input>
		                </div>
		                <div class="row col-3">
                      <q-btn label="Add Comment" class="full-width action-button"
                             @click="() => store.commit('setIsCommentDialogOpen', true)" color="bmx-blue"
                             icon-right="mdi-comment-text">
                      </q-btn>
                    </div>
	                </div>
                    <q-separator inset class="q-ma-md" />
                    <div class="row">
                        <p class="text-weight-bold text-bmx-blue text-uppercase">Fine Tuning Operations:</p>
                    </div>
	                  <q-table :rows="ft_cases" :columns="DETAILED_INSTRUMENT_HEADERS" :sortable="false">
		                  <template v-slot:header="props" >
			                  <q-tr :props="props">
				                  <q-th v-for="col in props.cols" :key="col.name" :props="props" class="tableHeader"  >
					                  {{ col.label }}
				                  </q-th>
			                  </q-tr>
		                  </template>
		                    <template v-slot:body="props">
			                      <q-tr :props="props" >
				                        <q-td v-for="keys in DETAILED_INSTRUMENT_HEADERS">
					                          <p>{{ props.row[keys.key_name] }}</p>
				                        </q-td>
			                      </q-tr>
		                    </template>
	                  </q-table>
                    <q-separator inset class="q-ma-md" />
                    <q-card>
                        <q-card-section>
	                        <div class="row">
		                        <p class="text-weight-bold text-bmx-blue text-uppercase">Monitoring: </p>
	                        </div>
                            <div class="row" v-if="selectedDate">
	                            <q-input  :input-style="{ minWidth: '175px' }" class="q-mr-md" filled label="Start date"  :model-value="`${min_date}`">
		                            <template v-slot:append>
			                            <q-icon name="event" class="cursor-pointer">
				                            <q-popup-proxy cover transition-show="scale" transition-hide="scale">
					                            <q-date color="bmx-blue" v-model="min_date">
						                            <div class="row items-center justify-end">
							                            <q-btn v-close-popup label="Close" color="primary" flat />
						                            </div>
					                            </q-date>
				                            </q-popup-proxy>
			                            </q-icon>
		                            </template>
	                            </q-input>
	                            <q-input :input-style="{ minWidth: '175px' }" class="q-mr-md" filled label="End date" :model-value="`${max_date}`">
		                            <template v-slot:append>
			                            <q-icon name="event" class="cursor-pointer">
				                            <q-popup-proxy cover transition-show="scale" transition-hide="scale">
					                            <q-date color="bmx-blue" v-model="max_date">
						                            <div class="row items-center justify-end">
							                            <q-btn v-close-popup label="Close" color="primary" flat />
						                            </div>
					                            </q-date>
				                            </q-popup-proxy>
			                            </q-icon>
		                            </template>
	                            </q-input>
	                            <q-btn label="Load Graph" color="bmx-blue" @click="displayGraph = true" icon-right="mdi-chart-scatter-plot"></q-btn>
                            </div>
	                        <q-separator inset class="q-ma-sm" />
	                        <div v-if="displayGraph" class="row items-center full-width">
                            <DetailedInstrumentGraph />
                          </div>
                        </q-card-section>
                    </q-card>
                </q-card-section>
            </q-card>
        </q-dialog>
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import Status from './utils/Status.vue';
import {DETAILED_INSTRUMENT_HEADERS} from "@/const";
import 'vue-loading-overlay/dist/css/index.css';
import DetailedInstrumentGraph from "@/components/DetailedInstrumentGraph.vue";
import RemainingDays from "@/components/utils/RemainingDays.vue";

const store = useStore();
const detailedInstrument = computed(() => store.getters.getDetailedInstrument);
const ft_cases = computed(() => store.getters.getInstrumentFTCases);
const displayGraph = ref(false);
const lastFtDate = computed(() => {
	if (store.getters.getLastFTDate === "") {
		return "No Fine Tuning Date";
	} else {
		return store.getters.getLastFTDate
	}
})

const min_date = ref("");
const max_date = ref("");

const selectedDate = computed({
	get() {
		let date = store.getters.getSelectedDate;
		if (date.min_date === "" || date.max_date === "") {
			return null;
		}
		min_date.value = date?.min_date?.replaceAll('-', '/');
		max_date.value = date?.max_date?.replaceAll('-', '/');
		return {
			min_date: min_date.value,
			max_date: max_date.value
		}
	}
})

watch(
		() => [min_date.value, max_date.value],
		() => {
			store.commit('setSelectedDate', {
				min_date: min_date.value,
				max_date: max_date.value
			})
		}
)

const isFeedbackDialogOpen = computed({
    get() {
        return store.getters.getIsFeedbackDialogOpen;
    },
    set() {
        store.commit('setIsFeedbackDialogOpen', false);
    }
})

const rows = computed(() => store.getters.getFineTuningCaseData);
const lastComment = computed(() => store.getters.getLastComment);

const closeFeedback = () => {
    isFeedbackDialogOpen.value = false;
}

</script>

<style scoped>
.q-dialog.feedback {
    overflow: hidden;
    overflow-y: auto;
}

.tableHeader {
	background: rgb(0, 66, 127);
	color: rgb(255 255 255);
}

.q-application p {
    margin-bottom: 0px;
}

.text-bmx-blue {
	color:  rgb(0, 66, 127);
}

.close-up {
		position: absolute;
}
</style>