
<template>
    <div class="row justify-center">
        <q-dialog v-model="isCommentDialogOpen" persistent class="feedback">
            <q-card style="max-width: 800px;min-width: 650px;">
                <!-- title -->
                <q-card-section align="center">
                    <div class="row items-center justify-center">
                        <div class="col-md-1">
                            <q-img width="30px" contain src="../assets/rating.png"></q-img>
                        </div>
                        <div class="col-md-3">
                            <span class="text-h6 text-weight-bold text-bmx-blue">Comment</span>
                        </div>
                    </div>
                </q-card-section>
                <q-card-section>
	                <div class="col-10 q-ma-lg" v-if="!isFeedbackDialogOpen">
		                <div class="col-6 no-padding no-margin">
			                <p class="">Last Comment</p>
		                </div>
		                <q-input  v-if="lastComment.comment && lastComment.comment !== ''" type="textarea" v-model="lastComment.comment" color="blue" disable
		                         autogrow outlined rows="2" row-height="15">
		                </q-input>
		                <q-input  v-if="!lastComment.comment" type="textarea"
		                         label="No comment" color="blue" disable
		                         autogrow outlined rows="2" row-height="15">
		                </q-input>
	                </div>
                    <div class="col-10 q-ma-lg">
	                    <div class="col-6 no-padding no-margin">
		                    <p class="">Add a new comment</p>
	                    </div>
                        <q-input type="textarea" v-model="comment"
                            label="What else did you change and what was the reason ? (mandatory if yes)" color="blue"
                            autogrow outlined rows="2" row-height="15"></q-input>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn class="action-button" text @click="closeDialog" label="Close"></q-btn>
                    <q-btn class="action-button" text @click="sendComment(detailedInstrument)"   color="bmx-blue" label="Save"></q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>
    </div>
</template>


<script setup>
import { computed, defineProps, ref } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
	instrumentID: Object
})

const store = useStore();
const detailedInstrument = computed(() => store.getters.getDetailedInstrument);
const isCommentDialogOpen = computed({
    get() {
        return store.getters.getIsCommentDialogOpen;
    },
    set() {
        store.commit('setIsCommentDialogOpen', false);
    }
})

const feedbackOptions = computed(() => store.getters.getFeedBackOptions);
store.dispatch("getLastInstrumentComment", {"id" : props.instrumentID});

const user = computed(() => store.getters.getUser);

const selectRecoDateRelevant = ref(null);
const comment = ref("");
const lastComment = computed(() => store.getters.getLastComment);
const rating = ref(0);

const closeDialog = () => {
    isCommentDialogOpen.value = false;
}

const isFeedbackDialogOpen = computed({
	get() {
		return store.getters.getIsFeedbackDialogOpen;
	},
	set() {
		store.commit('setIsFeedbackDialogOpen', false);
	}
})

const sendComment = (detailedInstrument) => {
		store.dispatch("putNewInstrumentComment", {instrument_ID : props.instrumentID ? props.instrumentID : detailedInstrument.instrument_id, comment : comment.value});
}

</script>

<style >
.q-dialog.feedback {
    overflow: hidden;
    overflow-y: auto;
}

.text-bmx-blue {
	color:  rgb(0, 66, 127);
}

.q-application p {
    margin-bottom: 0px;
}
</style>