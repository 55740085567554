<template>
  <div v-if="user.username !== '' && user.userId !== '' && user.email !== '' &&  userToken !== '' && AUTH_TOKEN && AUTH_TOKEN !== '' ">
	  <Header />
	  <div class="row justify-around">
	    <CardNumber :items="recommendedDateNumber" title="RECOMMENDED FT DATE"/>
	    <CardNumber :items="badSpottingNumber" title="BAD SPOTTING ALERT"/>
	  </div>
    <CustomersDevices />
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import CustomersDevices from "@/components/CustomersDevices.vue";
import Header from "@/components/Header.vue";
import {computed} from "vue";
import CardNumber from "@/components/CardNumber.vue";
const store = useStore();
const recommendedDateNumber = computed(() => store.getters.getRecommendedDatesNumber);
const badSpottingNumber = computed(() => store.getters.getBadSpottingNumber);
const user = computed(() => store.getters.getUser)
const AUTH_TOKEN = computed(() => localStorage.getItem('authToken'))
const userToken = computed(() => store.getters.getUserToken)
</script>