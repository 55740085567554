import { createRouter, createWebHistory } from 'vue-router'
import { Auth } from 'aws-amplify'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
    meta: { requiresAuth: true },
  },
  {
    path: '/EquipmentView',
    name: 'CustomerHome',
    component: () => import(/* webpackChunkName: "home" */ '@/views/CustomerHome'),
    children: [
        {
            path: '',
            name: 'EquipmentView',
            component: () => import(/* webpackChunkName: "equipmentView" */ '@/views/CustomerHome'),
        }
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  try {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      const userPayload = authenticatedUser.signInUserSession.idToken.payload;
      const user = {
        email: userPayload['email'],
        role: userPayload['custom:role'],
        userId: userPayload['identities'][0]['userId'],
        username: userPayload['cognito:username'],
      }
      if (user.email && user.userId && user.username) {
        next();
      } else {
        next("/error")
      }
    } else {
      if (to.path === '/EquipmentView') {
        next();
      } else {
        next({path: '/EquipmentView'});
      }
    }
  } catch (error) {
    await Auth.federatedSignIn({provider: "login.microsoftonline.com"});
    return error;
  }
})

export default router
