<template>
	<div class="row col-12">
		<div class="col-10 no-padding no-margin">
			<div class="row q-ml-lg">
				<div class="col-4">
					<q-select  color="bmx-blue"  @popup-hide="changeFTDate" outlined multiple v-model:model-value="selectedFTCategories" dense :options="FtDateCategories"  style="width:95%" label="Fine Tuning date - Category" :multiple="true">
						<template v-slot:option="{options, itemProps, opt, selected, toggleOption }">
							<q-item v-bind="itemProps" style="padding: 0">
								<q-item-section side style="padding-right:0">
									<q-checkbox  :model-value="selected" @update:model-value="toggleOption(opt)" />
								</q-item-section>
								<q-item-section>
									<q-item-label v-html="opt" />
								</q-item-section>
							</q-item>
						</template>
					</q-select>
				</div>
				<q-separator inset class="q-ma-sm" />
					<div class="row col-3 q-mb-sm">
						<q-checkbox color="bmx-blue" v-model="curveView" @click="updateCurve()" label="Curve view"></q-checkbox>
					</div>
			</div>
		</div>
		<div class="row col-12">
			<canvas id="myChart" style="max-height: 700px" width="100%" height="500"></canvas>
		</div>
	</div>
	<div class="row col-12" style="justify-content: center; font-style : italic; color:grey">
		<p> Click on legend to hide or display the data</p>
	</div>

</template>
<script setup>

import Chart from 'chart.js/auto';
import {onMounted, ref, computed, watch} from "vue";
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-moment';
import {CHART_CONFIG, FT_DATE, PREDICTION} from "@/const";
import {useStore} from "vuex";
const store = useStore();
const curveView = ref(false);
const warningDisplay = ref(true);
const fineTuningDateDisplay = ref(true);
const FtDateCategories = computed(() => store.getters.getFtDateCategories);
const detailedInstrument = computed(() => store.getters.getDetailedInstrument);

const chartData = computed(() => store.getters.getChartData)
let chart;
store.dispatch("getChartData")
store.dispatch("initFTDateCategories")

const newChartData = (chartData) => {
	let newData = {};
	for (const obj of chartData) {
		for (const line of obj?.data) {
			if (line.strain) {
				let key = `${obj.label} - ${line.strain}`
				if (newData[key]) {
					newData[key].data.push(line);
				}
				else {
					newData[key] = {
						...obj,
						data : [],
						backgroundColor : CHART_CONFIG.COLORS[key],
						borderColor : CHART_CONFIG.COLORS[key],
					}
					newData[key].data = [];
					newData[key].data.push(line);
				}
			} else {
				newData[obj.label] = obj;
				break;
			}
		}
	}
	return newData;
}

const createPredictionData = (chartData) => {
	let newData = newChartData(chartData);
	let res = Object.values(newData).map((obj) => {
		let label = obj.label;
		let showLine = false;
		let intersect = true;
		let showPoints = true;
		let pointBackgroundColor = null
		let radius = 3;
		let pointStyle = 'circle';
		let borderDash = [0, 0];
		if (obj?.data?.[0]?.strain && !label.includes(PREDICTION)) {
			label = obj.label + " - " + obj.data[0].strain;
		}
		if (label === FT_DATE) {
			showPoints = false;
			radius = 5;
			pointBackgroundColor = "rgba(255,0,255,0)";
		}
		if (label.includes(PREDICTION)) {
			borderDash = [5, 5];
			radius = 0;
			intersect = false;
			showLine = true;
			label = label.replace(` ${PREDICTION}`, '');
			label = `${PREDICTION} ${obj.data[0].strain} - ${label}`;
			label = label.charAt(0).toUpperCase() + label.slice(1);
		} else {
			showPoints = true;
		}
		if (obj?.data?.[0]?.strain && obj?.data?.[0]?.strain.includes("atcc")) {
			pointStyle = 'rectRot'
		}
		return {
			...obj,
			label : label,
			fill: false,
			showLine : showLine,
			showPoints : showPoints,
			borderDash : borderDash,
			tension : 0.3,
			intersect : intersect,
			radius : radius,
			pointStyle: pointStyle,
			pointBackgroundColor : pointBackgroundColor ?? CHART_CONFIG.COLORS[label],
			borderColor : pointBackgroundColor ?? CHART_CONFIG.COLORS[label],
		}
	})
	return res;
}

const createCurvedData = (chartData) => {
	let newData = newChartData(chartData);
	let res = Object.values(newData).map((obj) => {
		let label = obj.label;
		let showLine = true;
		let pointBackgroundColor = null;
		let showPoints = true;
		let intersect = true;
		let borderDash = [0, 0];
		if (obj?.data?.[0]?.strain && !label.includes(PREDICTION)) {
			label = obj.label + " - " + obj.data[0].strain;
		}
		if (label === FT_DATE) {
			showLine = false;
			showPoints = false;
		}
		if (label.includes(PREDICTION)) {
			borderDash = [5, 5];
			intersect = false;
			label = label.replace(` ${PREDICTION}`, '');
			label = `${PREDICTION} ${obj.data[0].strain} - ${label}`;
			label = label.charAt(0).toUpperCase() + label.slice(1);
		}
		return {
			...obj,
			label : label,
			fill: false,
			showLine : showLine,
			showPoints : showPoints,
			borderDash : borderDash,
			tension : 0.3,
			intersect : intersect,
			radius : 0,
			pointBackgroundColor : pointBackgroundColor ?? CHART_CONFIG.COLORS[label],
			borderColor : pointBackgroundColor ?? CHART_CONFIG.COLORS[label],
		}
	})
	return res;
}

 watch(chartData, () => {
	 chart = createChart();
 })

watch(() => store.getters.getSelectedDate, () => {
	store.dispatch("getChartData")
	store.dispatch("initFTDateCategories")

})

const changeFTDate = () => {
	store.dispatch("getChartData")
}

onMounted(() => {
	chart = createChart();
})

const selectedFTCategories = computed({
	get() {
		return store.getters.getSelectedFTDateCategories;
	},
	set(value) {
		store.commit('setSelectedFTDateCategories', value);
	}
})

const destroyChartIfExist = () => {
	let chart = Chart.getChart("myChart");
	if (chart) {
		chart.destroy();
	}
}


const createChart = () => {
	Chart.register(annotationPlugin);
	destroyChartIfExist()
	let chart = new Chart(document.getElementById("myChart"), createConfig());
	chart.id = "myChart";
	chart.update();
	return chart;
}

const updateCurve = () => {
	chart = createChart();
}

const createConfig = () => {
	warningDisplay.value = true;
	fineTuningDateDisplay.value = true;
	if (!chartData.value[FT_DATE] || !chartData.value["Warning level"]) {
		return;
	}
	let config = {...CHART_CONFIG.GLOBAL_CONFIG, data: []};
	let warningAnnotation = [];
	let dateAnnotation = [];
	if (fineTuningDateDisplay.value) {
		dateAnnotation = chartData?.value?.[FT_DATE]?.map((data) => {
			return {...CHART_CONFIG.ANNOTATION_CONFIG.ft_date, value: data.x}
		})
	}
	if (warningDisplay.value) {
		warningAnnotation = chartData?.value?.["Warning level"]?.map((data) => {
			return {...CHART_CONFIG.ANNOTATION_CONFIG.warning, yMin: data, yMax: data}
		})
	}
	config.options.plugins.annotation.annotations = [...warningAnnotation, ...dateAnnotation];
	config.options.plugins.legend.onClick = (e, legendItem, chart) => {
		if (legendItem.text === FT_DATE) {
			fineTuningDateDisplay.value = !fineTuningDateDisplay.value;
			hideAnnotation(chart.chart.config.options.plugins.annotation.annotations, legendItem.text);
		} else if (legendItem.text === "Warning level") {
			warningDisplay.value = !warningDisplay.value;
			hideAnnotation(chart.chart.config.options.plugins.annotation.annotations, legendItem.text);
		}
		Chart.defaults.plugins.legend.onClick(e, legendItem, chart)
	}
	config.data.datasets = createDatasets();
	return config;
}

const createDatasets = () => {
	let datasets = [];
	let type = detailedInstrument?.value?.instrument_type;
	if (!type || !CHART_CONFIG.DATA_TYPE[type]) {
		return;
	}
	for (const dataType of CHART_CONFIG.DATA_TYPE[type]) {
		let dataset = {...CHART_CONFIG.DATASET_CONFIG};
		dataset.label = dataType;
		dataset.data = dataType === "Warning level"  ? [] : chartData.value[dataType];
		if (dataset.data === undefined) {
			dataset.data = [];
		}
    dataset.data.sort((a, b) => {
      return new Date(a.x) - new Date(b.x);
    });
		if (dataType.includes(PREDICTION)) {
			dataset.showLine = true;
			dataset.showPoints = false;
			dataset.borderDash = [5, 5];
			dataset.tension = 0.3;
			dataset.fill = false;
			dataset.segment = {
				borderColor : context => skipped(context, 'rgba(0,255,0, 0)', {ftdate : chartData.value[FT_DATE]}),
				borderDash : context => skipped(context, [10, 5], {ftdate : chartData.value[FT_DATE]}),
			}
		}
		dataset.borderColor = CHART_CONFIG.COLORS[dataType];
		dataset.backgroundColor = CHART_CONFIG.COLORS[dataType];
		datasets.push(dataset);
	}
	if (curveView.value) {
		datasets = createCurvedData(datasets);
	} else {
		datasets = createPredictionData(datasets);
	}
	return datasets;
}

const skipped = (ctx, value, ftDate) => {
	for (const date of ftDate.ftdate) {
		if (ctx.p0.raw.x < date.x && ctx.p1.raw.x >= date.x) {
			return value;
		}
	}
	return undefined;
}

const hideAnnotation = (annotations, name) => {
	for (const annotation of annotations) {
		if (annotation.name === name) {
			annotation.display = !annotation.display;
		}
	}
	chart.update();
}
</script>