<template>
  <div>
	  <Header />
    <CustomersDevices />
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import CustomersDevices from "@/components/CustomersDevices.vue";
import Header from "@/components/Header.vue";
const store = useStore();

</script>
