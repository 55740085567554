<template>
  <div class="row q-mt-xs">
    <div class="col-3">
      <q-select use-input input-debounce="0" @filter="(val, update, abort) => { filterFn(val, update, abort, filters, 'region')}" @popup-hide="callFilteredInstrumentList" outlined multiple v-model:model-value="selectedRegion" :options="filtersOption?.region" color="bmx-blue" style="width:95%" label="Region" dense>
        <template v-slot:option="{options, itemProps, opt, selected, toggleOption }">
	        <q-item v-bind="itemProps" style="padding: 0">
		        <q-item-section side style="padding-right:0">
			        <q-checkbox :model-value="selected" @update:model-value="toggleOption(opt)" />
		        </q-item-section>
		        <q-item-section>
			        <q-item-label v-html="opt" />
		        </q-item-section>
	        </q-item>
        </template>
      </q-select>
    </div>
    <div class="col-3">
      <q-select use-input input-debounce="0" @filter="(val, update, abort) => { filterFn(val, update, abort, filters, 'customer_name')}" @popup-hide="callFilteredInstrumentList" outlined multiple v-model:model-value="selectedCustomerName" color="bmx-blue" dense  style="width:95%" :options="filtersOption?.customer_name" label="Customer Name">
        <template v-slot:option="{options, itemProps, opt, selected, toggleOption }">
          <q-item v-bind="itemProps" style="padding: 0">
            <q-item-section side style="padding-right:0">
              <q-checkbox :model-value="selected" @update:model-value="toggleOption(opt)" />
            </q-item-section>
            <q-item-section>
              <q-item-label v-html="opt" />
            </q-item-section>
          </q-item>
        </template>
      </q-select>
    </div>
    <div class="col-3">
      <q-select use-input input-debounce="0" @filter="(val, update, abort) => { filterFn(val, update, abort, filters, 'instrument_type')}" @popup-hide="callFilteredInstrumentList" outlined multiple v-model:model-value="selectedInstrumentType" dense :options="filtersOption?.instrument_type" color="bmx-blue"  style="width:95%" label="Vitek MS Type" :multiple="true">
        <template v-slot:option="{options, itemProps, opt, selected, toggleOption }">
          <q-item v-bind="itemProps" style="padding: 0">
            <q-item-section side color="bmx-blue" style="padding-right:0">
              <q-checkbox :model-value="selected" @update:model-value="toggleOption(opt)" />
            </q-item-section>
            <q-item-section color="bmx-blue">
              <q-item-label v-html="opt" />
            </q-item-section>
          </q-item>
        </template>
      </q-select>
    </div>
    <div class="col-3" v-if="!isCustomer">
      <q-select use-input input-debounce="0"  @filter="(val, update, abort) => { filterFn(val, update, abort, filters, 'instrument_ID')}" @popup-hide="callFilteredInstrumentList" outlined multiple v-model:model-value="selectedInstrumentID" color="bmx-blue" dense  style="width:95%" :options="filtersOption?.instrument_ID" label="Instrument  ID">
        <template v-slot:option="{options, itemProps, opt, selected, toggleOption }">
          <q-item v-bind="itemProps" style="padding: 0">
            <q-item-section side style="padding-right:0">
              <q-checkbox :model-value="selected" @update:model-value="toggleOption(opt)" />
            </q-item-section>
            <q-item-section>
              <q-item-label v-html="opt" />
            </q-item-section>
          </q-item>
        </template>
      </q-select>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { ref } from "vue";
const store = useStore();
const isCustomer = computed(() => {
	return store.getters.getIsCustomerVue;
})
const filters = computed(() => {
	filtersOption.value = {...store.getters.getFilters};
	return store.getters.getFilters;
})

const filtersOption = ref({
	region: [],
	customer_name: [],
	instrument_type: [],
	instrument_ID: []
})

const filterFn = (value, update, abort, filters, type) => {
	if (value === '') {
		update(() => {
			filtersOption.value[type] = filters[type];
		})
		return
	}
	update(() => {
		const needle = value.toLowerCase()
		filtersOption.value[type] = filters[type].filter(v => v.toLowerCase().indexOf(needle) > -1)
	})
}

const selectedRegion = computed({
  get() {
    return store.getters.getSelectedRegion;
  },
  set(value) {
    store.commit('setSelectedRegion', value);
  }
})

const selectedCustomerName = computed({
  get() {
    return store.getters.getSelectedCustomerName;
  },
  set(value) {
    store.commit('setSelectedCustomerName', value);
  }
})

const selectedInstrumentID = computed({
  get() {
    return store.getters.getSelectedInstrumentID;
  },
  set(value) {
    store.commit('setSelectedInstrument', value);
  }
})

const selectedInstrumentType = computed({
  get() {
    return store.getters.getSelectedInstrumentType;
  },
  set(value) {
    store.commit('setSelectedInstrumentType', value);
  }
})
const callFilteredInstrumentList = () => {
	store.dispatch("getFilteredInstrumentList");
	store.dispatch("getFiltersPage")
}

</script>