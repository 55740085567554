<template>
  <q-layout>
    <q-page-container>
      <Home v-if="!isCustomer" />
	    <CustomerHome  v-if="isCustomer" />
	    <loading :active="isLoading === 0 ? false : true" :is-full-page="true"/>
    </q-page-container>
</q-layout>
</template>

<script setup>
import Loading from 'vue-loading-overlay';
import Home from "@/views/Home.vue";
import CustomerHome from "@/views/CustomerHome.vue";
import {computed, onMounted, watch} from "vue";
import {useStore} from "vuex";
import { Auth, Hub } from "aws-amplify";

const store = useStore();
const isCustomer = computed(() => store.getters.getIsCustomerVue)
let isLoading = computed(() => store.getters.getIsLoading)
import {useRoute} from 'vue-router';
const route = useRoute();

onMounted(() => {
	Hub.listen("auth", ({ payload: { event, data } }) => {
		switch (event) {
			case "signIn":
			case "cognitoHostedUI":
				store.dispatch('getUserInfo');
				break;
			case "signOut":
				Auth.federatedSignIn({ provider: "login.microsoftonline.com" });
				break;
			case "signIn_failure":
			case "cognitoHostedUI_failure":
				console.log("Sign in failure", data);
				break;
		}
	});
})

watch(
		() => route,
		() => {
			if (route.path !==  "/EquipmentView") {
				try {
					store.dispatch('getUserInfo');
				} catch {
					Auth.federatedSignIn({ provider: "login.microsoftonline.com" });
				}
			}
		},
		{
			deep:true
		}
);

</script>

<style>
.text-bmx-blue {
  color: #00427F !important
}

.bg-bmx-blue {
  background-color: #00427F !important
}
</style>