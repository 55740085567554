/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from './App.vue'
import store from './store'
// Composables
import { createApp } from 'vue'

// Plugins
import { registerPlugins } from '@/plugins'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import { Amplify } from 'aws-amplify'

Amplify.configure({
	Auth: {
		region: process.env.VUE_APP_AWS_REGION,
		userPoolId: process.env.VUE_APP_AWS_COGNITO_APP_USER_POOL_ID,
		userPoolWebClientId: process.env.VUE_APP_AWS_COGNITO_APP_USER_POOL_CLIENT_ID,
		oauth: {
			domain: process.env.VUE_APP_AWS_COGNITO_APP_USERPOOL_DOMAIN,
			scope: [
				"email",
				"openid",
				"profile"
			],
			redirectSignIn: window.location.origin,
			responseType: "code"
		},
	},
})

const app = createApp(App).use(Quasar, quasarUserOptions, Amplify)

app.use(store)
registerPlugins(app)

app.mount('#app')
