<template>
    <div
		    :class="colorOnDate(dateDifference(date))"
    >{{ dateText(date)}}</div>
</template>

<script setup>
import { defineProps } from 'vue'
import {dateDifference} from "@/tools";

function dateText(date) {
	let dateDiff = dateDifference(date);
	if (dateDiff === 0) return "Today";
	if (dateDiff === null) return null;
	return dateDiff + " days";
}

function colorOnDate(days) {
	if (days <= 0) return "red_text";
	if (days > 0 && days <= 3) return "orange_text";
	if (days > 3 && days <= 7) return "light_green_text";
	return "green_text";
}

const { date } = defineProps({
    date: {
        type: String,
        required: true
    }
})

</script>

<style scoped>
.red_text {
		color: red;
}

.green_text {
		color: green;
}

.light_green_text {
		color: rgb(120, 195, 74);
}

.orange_text {
		color: orange;
}
</style>