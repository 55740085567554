<template>
	<div class="row justify-center">
		<q-dialog v-model="isFineTuningDateDialogOpen" persistent class="feedback">
			<q-card style="max-width: 800px;">
				<q-card-section align="center">
					<div class="row items-center justify-center">
						<div class="col-md-1">
							<q-img width="30px" contain src="../assets/rating.png"></q-img>
						</div>
						<div class="col-md-6">
							<span class="text-h6 text-weight-bold text-bmx-blue"> New Fine Tuning Date</span>
						</div>
					</div>
				</q-card-section>
				<q-card-section>
					<div class="col-10 q-ma-lg">
						<div class="col-12 no-padding no-margin">
							<p class="col-12">Date</p>
						</div>
						<q-input :input-style="{ minWidth: '175px' }" class="q-mr-md" filled  :model-value="date ? date : 'Empty' ">
							<template v-slot:append>
								<q-icon name="event" class="cursor-pointer">
									<q-popup-proxy cover transition-show="scale" transition-hide="scale">
										<q-date :options="limitDate" color="bmx-blue" v-model="date">
											<div class="row items-center justify-end">
												<q-btn color="bmx-blue" v-close-popup label="Close" flat />
											</div>
										</q-date>
									</q-popup-proxy>
								</q-icon>
							</template>
						</q-input>
					</div>
				</q-card-section>

				<q-card-actions align="right">
					<q-btn class="action-button" text @click="closeDialog" label="Close"></q-btn>
					<q-btn class="action-button" text @click="() => date !== '' ? confirm = true :confirm = false" label="Save" color="bmx-blue"></q-btn>
				</q-card-actions>
			</q-card>
		</q-dialog>
		<q-dialog v-model="confirm" persistent>
			<q-card>
				<q-card-section class="row items-center">
					<q-avatar icon="warning" color="primary" text-color="white" />
					<span class="q-ml-sm">Are you sure to save "{{date}}" as new Fine Tuning Date ?</span>
				</q-card-section>

				<q-card-actions align="right">
					<q-btn class="action-button" text @click="closeConfirm" v-close-popup label="Cancel"></q-btn>
					<q-btn class="action-button" text @click="sendFineTuningDate" v-close-popup label="Save" color="bmx-blue"></q-btn>
				</q-card-actions>
			</q-card>
		</q-dialog>
	</div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const isFineTuningDateDialogOpen = computed({
	get() {
		return store.getters.getIsFineTuningDateDialogOpen;
	},
	set() {
		store.commit('setIsFineTuningDateDialogOpen', false);
	}
})

const limitDate = (date) => {
	let today = (new Date()).toISOString().slice(0, 10).replace(/-/g, "/");
	return date <= today;
}

const date = ref("");
let confirm = ref(false);

const closeDialog = () => {
	isFineTuningDateDialogOpen.value = false;
}

const closeConfirm = (confirm) => {
	confirm = false;
}
const sendFineTuningDate = () => {
	store.dispatch('createNewFTDate', {date : date.value});
	store.commit('setLastFTDate', date.value)
	closeConfirm();
	closeDialog();
}

</script>

<style >
.q-dialog.feedback {
	overflow: hidden;
	overflow-y: auto;
}

.text-bmx-blue {
	color:  rgb(0, 66, 127);
}

.q-application p {
	margin-bottom: 0px;
}
</style>