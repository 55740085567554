import {createStore} from 'vuex';
import dataStore from './modules/dataStore';


const store = createStore({
	modules: {
		dataStore
	}
})

export default store