import customersDevices from "@/store/modules/customersDevices";
import {BAD_SPOTTING_NUMBERS, DATA_TABLE_COLUMNS, RECOMMENDED_DATE_NUMBERS, VITEK_MS} from "@/const";
import moment from "moment";
import { Auth } from "aws-amplify";
import {dateDifference, recommendedDateStatus} from "@/tools";

const state = {
  data: [],
  user: {
    email : "",
    role : "",
    userId : "",
    username : "",
    isLogged: false,
  },
  userToken : "",
  isLoading: 0,
  filters: {},
  detailedInstrument : {},
  instrumentFTCases : [{id: 1, name: "FT_CASE_ID_OR_NUMBER", items: []}],
  regions: [],
  lastComment: {},
  vitekMsTypes: [],
  isCustomerVue: true,
  customerToken: "",
  customerEquipmentSN: "",
  customerEquipmentSNStatusMessage: "",
  instruments: [],
  statusList: [],
  lastFTDate: "",
  selectedRegion: [],
  selectedCustomerName: [],
  selectedInstrumentType: [],
  selectedInstrument: [],
  selectedStatus: [],
  selectedBadSpotting: [],
  selectedDate: {
    min_date : "",
    max_date : ""
  },
  dates: ["02/11/1196", "02/05/2021"],
  lastUpdate: { date: "", isFresh: false },
  customers: [],
  chartData: {},
  ftDateCategories: [],
  selectedFTDateCategories: [],
  instrumentList: [],
  filteredInstrumentList: [],
  dataTableColumns: DATA_TABLE_COLUMNS,
  fineTuningCaseData: [],
  selectedItem: {},
  isFeedbackDialogOpen: false,
  isFineTuningDateDialogOpen: false,
  feedBackOptions: {
    binaryOptions: [
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
    timeOptions: [
      { label: "In time", value: "INTIME" },
      { label: "Too late", value: "TOOLATE" },
      { label: "Too early", value: "TOOEARLY" },
    ],
    spotQualityOptions: [
      { label: "Good or good enough", value: "GOOD" },
      { label: "Bad", value: "BAD" },
    ],
    isCommentDialogOpen: false,
  },
  selectedRecommendedDate: [],
  recommendedDatesNumber : RECOMMENDED_DATE_NUMBERS,
  badSpottingNumber : BAD_SPOTTING_NUMBERS
};

const getters = {
  getData(state) {
    return state.data;
  },
  getUser(state) {
    return state.user;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
  getRegions(state) {
    return state.regions;
  },
  getLastUpdate(state) {
    return state.lastUpdate;
  },
  getCustomersName(state) {
    return state.customerName;
  },
  getCustomers(state) {
    return state.customers;
  },
  getVitekMsTypes(state) {
    return state.vitekMsTypes;
  },
  getInstruments(state) {
    return state.instruments;
  },
  getSelectedStatus(state) {
    return state.selectedStatus;
  },
  getSelectedBadSpotting(state) {
    return state.selectedBadSpotting;
  },
  getSelectedDate(state) {
    return state.selectedDate;
  },
  getSelectedCustomerName(state) {
    return state.selectedCustomerName;
  },
  getSelectedInstrumentType(state) {
    return state.selectedInstrumentType;
  },
  getSelectedInstrumentID(state) {
    return state.selectedInstrument;
  },
  getSelectedRegion(state) {
    return state.selectedRegion;
  },
  getSelectedItem(state) {
    return state.selectedItem;
  },
  getInstrumentList(state) {
    return state.instrumentList;
  },
  getFilteredInstrumentList(state) {
    return state.filteredInstrumentList;
  },
  getDataTableColumns(state) {
    return state.dataTableColumns;
  },
  getStatusList(state) {
    return state.statusList;
  },
  getDates(state) {
    return state.dates;
  },
  getIsFeedbackDialogOpen(state) {
    return state.isFeedbackDialogOpen;
  },
  getIsFineTuningDateDialogOpen(state) {
    return state.isFineTuningDateDialogOpen;
  },
  getFeedBackOptions(state) {
    return state.feedBackOptions;
  },
  getIsCommentDialogOpen(state) {
    return state.isCommentDialogOpen;
  },
  getFineTuningCaseData(state) {
    return state.fineTuningCaseData;
  },
  getDetailedInstrument(state) {
    return state.detailedInstrument;
  },
  getInstrumentFTCases(state) {
    return state.instrumentFTCases;
  },
  getFilters(state) {
    return state.filters;
  },
  getSelectedFilters(state) {
    return state.selectedFilters;
  },
  getLastComment(state) {
    return state.lastComment;
  },
  getChartData(state) {
    return state.chartData;
  },
  getFtDateCategories(state) {
    return state.ftDateCategories;
  },
  getSelectedFTDateCategories(state) {
    return state.selectedFTDateCategories;
  },
  getCustomerToken(state) {
    return state.customerToken;
  },
  getCustomerEquipmentSN(state) {
    return state.customerEquipmentSN;
  },
  getIsCustomerVue(state) {
    return state.isCustomerVue;
  },
  getCustomerEquipmentSNStatusMessage(state) {
    return state.customerEquipmentSNStatusMessage;
  },
  getLastFTDate(state) {
    return state.lastFTDate;
  },
  getUserToken(state) {
    return state.userToken;
  },
  getSelectedRecommendedDate(state) {
    return state.selectedRecommendedDate;
  },
  getRecommendedDatesNumber(state) {
    return state.recommendedDatesNumber;
  },
  getBadSpottingNumber(state) {
    return state.badSpottingNumber;
  }
};

const mutations = {
  setData(state, data) {
    state.data = data;
  },
  setInstrumentList(state, instrumentList) {
    state.instrumentList = instrumentList;
  },
  setFilteredInstrumentList(state, filteredInstrumentList) {
    state.filteredInstrumentList = filteredInstrumentList;
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setRegionsName(state, regions) {
    state.regions = regions;
  },
  setLastUpdate(state, lastUpdate) {
    state.lastUpdate = lastUpdate;
  },
  setSelectedInstrumentStatus(state, selectedInstrumentStatus) {
    state.selectedInstrumentStatus = selectedInstrumentStatus;
  },
  setSelectedDate(state, selectedDate) {
    state.selectedDate = selectedDate;
  },
  setSelectedRegion(state, selectedRegion) {
    state.selectedRegion = selectedRegion;
  },
  setSelectedCustomerName(state, selectedCustomerName) {
    state.selectedCustomerName = selectedCustomerName;
  },
  setSelectedInstrumentType(state, selectedInstrumentType) {
    state.selectedInstrumentType = selectedInstrumentType;
  },
  setSelectedInstrument(state, selectedInstrument) {
    state.selectedInstrument = selectedInstrument;
  },
  setSelectedItem(state, selectedItem) {
    state.selectedItem = selectedItem;
  },
  setIsFeedbackDialogOpen(state, isFeedbackDialogOpen) {
    state.isFeedbackDialogOpen = isFeedbackDialogOpen;
  },
  setIsCommentDialogOpen(state, isCommentDialogOpen) {
    state.isCommentDialogOpen = isCommentDialogOpen;
  },
  setIsFineTuningDateDialogOpen(state, isFineTuningDateDialogOpen) {
    state.isFineTuningDateDialogOpen = isFineTuningDateDialogOpen;
  },
  setDetailedInstrument(state, detailedInstrument) {
    state.detailedInstrument = detailedInstrument;
  },
  setInstrumentFTCases(state, instrumentFTCases) {
    state.instrumentFTCases = instrumentFTCases;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  setLastComment(state, lastComment) {
    state.lastComment = lastComment;
  },
  setStatusList(state, statusList) {
    state.statusList = statusList;
  },
  setSelectedStatus(state, selectedStatus) {
    state.selectedStatus = selectedStatus;
  },
  setSelectedBadSpotting(state, selectedBadSpotting) {
    state.selectedBadSpotting = selectedBadSpotting;
  },
  setChartData(state, chartData) {
    state.chartData = chartData;
  },
  setFtDateCategories(state, ftDateCategories) {
    state.ftDateCategories = ftDateCategories;
  },
  setSelectedFTDateCategories(state, selectedFTDateCategories) {
    state.selectedFTDateCategories = selectedFTDateCategories;
  },
  setCustomerToken(state, customerToken) {
    state.customerToken = customerToken;
  },
  setCustomerEquipmentSN(state, customerEquipmentSN) {
    state.customerEquipmentSN = customerEquipmentSN;
  },
  setIsCustomerVue(state, isCustomerVue) {
    state.isCustomerVue = isCustomerVue;
  },
  setUser(state, user) {
    state.user = user;
  },
  SetInstrumentIDStatusMessage(state, customerEquipmentSNStatusMessage) {
    state.customerEquipmentSNStatusMessage = customerEquipmentSNStatusMessage;
  },
  setLastFTDate(state, lastFTDate) {
    state.lastFTDate = lastFTDate;
  },
  setUserToken(state, userToken) {
    state.userToken = userToken;
  },
  setSelectedRecommendedDate(state, selectedRecommendedDate) {
    state.selectedRecommendedDate = selectedRecommendedDate;
  },
  setRecommendedDatesNumber(state, recommendedDatesNumber) {
    state.recommendedDatesNumber = recommendedDatesNumber;
  },
  setBadSpottingNumber(state, badSpottingNumber) {
    state.badSpottingNumber = badSpottingNumber;
  }
};

const actions = {
  async getInstrumentIdFromToken({ commit, dispatch, getters, state }) {
    let token = getters.getCustomerToken;
    if (!token) {
      return;
    }
    let res = await customersDevices.getInstrumentIdFromToken(token, state);
    if (res?.status && res.status !== 200) {
      commit("SetInstrumentIDStatusMessage", "Not Found");
      return;
    }
    commit("setCustomerEquipmentSN", [res[0].instrument_id]);
    // commit("setSelectedInstrument",  [res[0].instrument_id]);
    dispatch("getFilteredInstrumentList")
    dispatch("getFiltersPage")
  },

  async getUserInfo({ commit, dispatch }) {
    try {
      dispatch("addLoadingProcess")
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      const userPayload = authenticatedUser.signInUserSession.idToken.payload;
      const user = {
        email: userPayload['email'],
        role: userPayload['custom:role'],
        userId: userPayload['identities'][0]['userId'],
        username: userPayload['cognito:username'],
      }
      if (authenticatedUser.signInUserSession.accessToken.jwtToken)
      {
        localStorage.setItem("authToken", authenticatedUser.signInUserSession.accessToken.jwtToken)
        setTimeout(() => {
          commit("setUserToken",authenticatedUser.signInUserSession.accessToken.jwtToken)
        }, 500);
        // commit("setUserToken",authenticatedUser.signInUserSession.accessToken.jwtToken)
        commit('setUser', {...user, loggedIn : true});
      }
      dispatch("removeLoadingProcess")
      return user;
    } catch {
      await Auth.federatedSignIn({provider: "login.microsoftonline.com"});
      dispatch("removeLoadingProcess")
    }
  },

  async getChartData({ commit, dispatch, getters }) {
    let id = getters.getDetailedInstrument.instrument_ID
    let instrumentType = getters.getDetailedInstrument.instrument_type
    let date = getters.getSelectedDate;
    let ftDateCategories = getters.getSelectedFTDateCategories;
    let payload = {
        "instrument_ID": id,
        ...date,
    }
    dispatch("addLoadingProcess");
    let res = await customersDevices.getChartData(state, payload);
    payload.ft_date_categories = Object.values(ftDateCategories).join(",");
    let ft_date = await customersDevices.getFTDate(state, payload);
    if (res?.status || ft_date?.status) {
      dispatch("removeLoadingProcess");
        return;
    }
    let data = customersDevices.constructChartData(res, ft_date, instrumentType);
    commit("setChartData", data.parsedData);
    dispatch("removeLoadingProcess");
  },

  async initFTDateCategories({ commit, dispatch, getters }) {
    dispatch("addLoadingProcess");
    let id = getters.getDetailedInstrument.instrument_ID
    let date = getters.getSelectedDate;
    let payload = {
      "instrument_ID": id,
      ...date,
    }
    let ft_date = await customersDevices.getFTDate(state, payload);
    if (ft_date?.status) {
      dispatch("removeLoadingProcess");
      return;
    }
    let ft_date_categories = ft_date.map((item) => item.category);
    commit("setFtDateCategories",  [...new Set(ft_date_categories)]);
    dispatch("removeLoadingProcess");
  },

  async createNewFTDate({ commit, dispatch, getters }, payload) {
    dispatch("addLoadingProcess")
    let res = await customersDevices.putFTDate(state, payload);
    dispatch("getDetailedInstrumentData")
    dispatch("removeLoadingProcess")
  },

  async getFilteredInstrumentList ({ commit, dispatch, getters}, payload) {
    let route = payload?.route;
    dispatch("addLoadingProcess")
    let filters = {
      "region" : getters.getSelectedRegion.join(","),
      "customer_name" : getters.getSelectedCustomerName.join(","),
      "instrument_type" : getters.getSelectedInstrumentType.join(","),
      "instrument_ID" : getters.getSelectedInstrumentID.join(",")
    };
    let actualInstrumentList = state?.filters;
    if (Object.keys(actualInstrumentList).length === 0 && route) {
        filters.instrument_ID = route;
        commit("setSelectedInstrument", [route]);
    }
    let res = await customersDevices.getInstrumentList(state, filters);
    if (res?.status) {
      dispatch("removeLoadingProcess");
      return;
    }
    dispatch("constructRecommendedDatesAndBadSpotting", res);
    commit("setInstrumentList", res);
    commit("setFilteredInstrumentList", res);
    dispatch("removeLoadingProcess")
    dispatch("filterDataTable")
  },

  constructRecommendedDatesAndBadSpotting({ commit, dispatch }, instrumentList) {
    let recommendedDatesNumber = structuredClone(RECOMMENDED_DATE_NUMBERS);
    let badSpottingNumber = structuredClone(BAD_SPOTTING_NUMBERS);

    let totalVitekMs = 0;
    for (const instrument of instrumentList) {
      let recommendedDate = instrument.recommended_ft_date;
      let badSpotting = instrument.bad_spotting_level;
      if (instrument.instrument_type === VITEK_MS){
        totalVitekMs++;
        if (recommendedDate === null) {
          recommendedDatesNumber["No recommended date"].number++;
        } else {
          recommendedDatesNumber[recommendedDateStatus(dateDifference(recommendedDate))].number++;
        }
        if (badSpotting === null) {
          badSpottingNumber["No bad spotting"].number++;
        } else {
          badSpottingNumber[badSpotting].number++;
        }
      }
    }
    recommendedDatesNumber.all.number = totalVitekMs;
    badSpottingNumber.all.number = totalVitekMs;
    commit("setRecommendedDatesNumber", recommendedDatesNumber);
    commit("setBadSpottingNumber", badSpottingNumber);
  },

  async getLastInstrumentComment({ commit, dispatch }, payload) {
    dispatch("addLoadingProcess")
    let res = await customersDevices.getLastInstrumentComment(state, payload);
    if (res?.status) {
      dispatch("removeLoadingProcess");
      return;
    }
    commit("setLastComment", res);
    dispatch("removeLoadingProcess");
  },

  async putNewInstrumentComment({ commit, dispatch }, payload) {
    dispatch("addLoadingProcess")
    let res = await customersDevices.putInstrumentComment(payload, state);
    if (res?.status) {
      dispatch("removeLoadingProcess");
      return;
    }
    commit("setLastComment", {comment : payload.comment});
    dispatch("removeLoadingProcess");
  },

  async getDetailedInstrumentData({ commit, dispatch }, payload) {
    dispatch("addLoadingProcess")
    let res = await customersDevices.getInstrumentDetail(state);
    let ft_cases = [];
    for (const ft_case of res?.ft_cases) {
      ft_cases.push({
        ...ft_case,
        creation_date: ft_case.creation_date ? ft_case.creation_date.split("T")[0] : ft_case.ft_date.split("T")[0]
      });
    }
    if (res?.status) {
      dispatch("removeLoadingProcess");
      return;
    }
    commit("setDetailedInstrument", res.instrument);
    commit("setInstrumentFTCases", ft_cases);
    dispatch("removeLoadingProcess")
  },

  async getFiltersPage({ commit, dispatch, getters }, payload) {
    dispatch("addLoadingProcess")
    let route = payload?.route;
    let filters = {
      "region" : getters.getSelectedRegion.join(","),
      "customer_name" : getters.getSelectedCustomerName.join(","),
      "instrument_type" : getters.getSelectedInstrumentType.join(","),
      "instrument_ID" : getters.getSelectedInstrumentID.join(",")
    };
    let actualFilterList = state?.instrumentList;
    if (actualFilterList.length === 0 && route) {
      filters.instrument_ID = route;
    }
    let res = await customersDevices.getFiltersPage(state, filters);
    if (res?.status) {
      dispatch("removeLoadingProcess");
      return;
    }
    for (const filtersType in res) {
      res[filtersType] = res[filtersType].map((item) => {
		if (item === "" || item === null) {
          return "N/A";
        }
        return item;
        });
    }
    commit("setFilters", res);
    let newStatusList = Object.values(getters.getSelectedStatus).concat(res.instrument_status);
    newStatusList = [...new Set(newStatusList)];
    commit("setStatusList", newStatusList);
    dispatch("removeLoadingProcess")
  },

  async getLastUpdateDate({ commit, dispatch }) {
    dispatch("addLoadingProcess")
    let res = await customersDevices.getLastUpdateDate(state);
    if (res?.status) {
      dispatch("removeLoadingProcess");
      return;
    }
    commit("setLastUpdate", {date : res.lastSuccessDate.split("T")[0], isFresh: true });
    dispatch("removeLoadingProcess")
  },

  filterDataTable({ commit }) {
    let tmpData = state.instrumentList;
    if (state.selectedStatus.length && !state.selectedStatus.includes("All"))
      tmpData = tmpData.filter((item) => {
	      return state.selectedStatus.includes(item.instrument_status.toString())
      });
    if (state.selectedRecommendedDate.length) {
      tmpData = tmpData.filter((item) => {
				let diff = dateDifference(item.recommended_ft_date);
				for (const range of state.selectedRecommendedDate) {
					switch (range) {
						case "Overdue":
							if (diff < 0) {
								return true;
							}
							break;
						case "More than a week":
							if (diff > 7) {
								return true;
							}
							break;
						case "In the next 3 days":
							if (diff <= 3 && diff >= 0 && diff !== null) {
								return true;
							}
							break;
						case "Between 3 and 7 days":
							if (diff > 3 && diff <= 7) {
								return true;
							}
							break;
					}
	      }
      })
    }
    if (state.selectedBadSpotting.length) {
      tmpData = tmpData.filter((item) => {
        if (item.bad_spotting_level !== null) {
          return state.selectedBadSpotting.includes(BAD_SPOTTING_NUMBERS[item.bad_spotting_level]?.title);
        }
        return state.selectedBadSpotting.includes(item.bad_spotting_level);
      });
    }
    commit("setFilteredInstrumentList", tmpData);
  },

  setDefaultSelectedDate({ commit }) { // set default date between a year ago (min_date) and today (max_date)
    let date = {
        min_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
        max_date: moment().add(40, "days").format("YYYY-MM-DD")
    }
    commit("setSelectedDate", date);
  },

  addLoadingProcess({commit}) {
    let nbProcess = state.isLoading;
    commit("setIsLoading", nbProcess + 1);
  },

  removeLoadingProcess({commit}) {
    let nbProcess = state.isLoading;
    commit("setIsLoading", nbProcess - 1);
  },

  async getLastFTDateFromID({ commit, dispatch, getters }, payload) {
    dispatch("addLoadingProcess")
    let res = await customersDevices.getLastFTDateFromID(state, payload);
    let isoDate = res[0]?.date ? res[0]?.date.split("T")[0] : "";
    commit("setLastFTDate", isoDate);
    dispatch("removeLoadingProcess")
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
