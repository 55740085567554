export const dateDifference = (date1, option = "after") => {
	if (date1 === ""|| date1 === null) {
		return null;
	} else if (date1 === 0) {
		return 0;
	}
	date1 = new Date(date1)
	let date2 = new Date()
	const _MS_PER_DAY = 1000 * 60 * 60 * 24;

	const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
	const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
	if (option === "before") {
		return Math.floor((utc2 - utc1) / _MS_PER_DAY);
	}
	return Math.floor((utc1 - utc2) / _MS_PER_DAY);
}

export const recommendedDateStatus = (days) => {
	switch (true) {
		case days < 0:
			return "overdue";
		case days >= 0 && days <= 3:
			return "inNext3Days";
		case days >= 4 && days <= 7:
			return "between3And7Days";
		case days > 7:
			return "moreThanAWeek";
	}
}