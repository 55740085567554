<template>
  <div class="grey lighten-5 q-pa-md ">
    <q-card class="q-pa-md">
      <div class="row">
	      <div class="col-1 self-center" >
		      <q-img height="80px" width="80px" src="@/assets/logo-biomerieux.jpg" style="justify-content: center; display: flex; margin-inline: auto;"></q-img>
	      </div>
	      <div class="col-2 column items-center">
		      <div class="text-body1">Last Update</div>
		      <div class="text-body1">{{ lastUpdate?.date }}</div>
		      <div>
			      <div v-if="lastUpdate?.isFresh === true">
				      <q-icon size="lg" color="green" name="mdi-check-circle"></q-icon>
			      </div>
			      <div v-else>
				      <q-icon size="lg" name="mdi-close-circle" color="red"></q-icon>
			      </div>
		      </div>
		      <div>
			      <p class="text-body1 text-bmx-blue" color="bmx-blue">Assistant version {{version}}</p>
		      </div>
	      </div>
        <div class="col-8">
          <div class="text-h6 q-ma-xs">Fine Tuning Assistant</div>
	        <div>Based on prediction of Fine Tuning due dates, this application optimizes field scheduling & management of the operation. </div>
	        <FilterZone v-if="!isCustomer"/>
	        <div  v-if="isCustomer && (instrument_id[0] || instrument_status)" class="text-h6 q-ma-xs">
	          Equipment : {{ instrument_status !== "" ? instrument_status : instrument_id[0] }}
	        </div>
        </div>
	      <div class="col-1">
		      <q-icon height="80px" width="80px" style="justify-content: center; display: flex; margin-inline: auto;" @click="openTutoLink()" name="mdi-information"  color="black" size="6em" class="q-mt-md" @mouseover="hover = true" @mouseleave="hover = false"></q-icon>
		      <q-card v-if="hover">
		      </q-card>
	      </div>
      </div>
    </q-card>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { ref } from "vue";
import FilterZone from "@/components/FilterZone.vue";
import { TUTO_LINK } from "@/const";
const store = useStore();
import { version } from "../../package.json";

const openTutoLink = () => {
	window.open(TUTO_LINK, "_blank", "noopener noreferrer");
}

const lastUpdate = computed(() => {
	return store.getters.getLastUpdate;
})

const instrument_status = computed(() => {
	return store.getters.getCustomerEquipmentSNStatusMessage;
})

const isCustomer = computed(() => {
	return store.getters.getIsCustomerVue;
})

const instrument_id = computed(() => {
	return store.getters.getCustomerEquipmentSN;
})

const hover = ref(false);

</script>