<template>
    <q-icon v-if="dateDifference(date, 'before') > 7" name="mdi-bell-alert" size="sm" color="red" />
    <div>{{ dateText(date)}}</div>
</template>

<script setup>
import { defineProps } from 'vue'
import {dateDifference} from "@/tools";

function dateText(date) {
	let dateDiff = dateDifference(date, 'before')
	if (dateDiff === 0) {
		return "Today"
	} else if (dateDiff === null) {
		return "N/A"
	} else {
		return dateDiff + " days"
	}
}

const { date } = defineProps({
    date: {
        type: String,
        required: true
    }
})

</script>